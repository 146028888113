<template>
  <page-container :title="pageTitle">
    <div class="box-container">
      <div class="box-container-inner"  style="margin: -30px -30px -25px -30px">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 20%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="姓名" prop="checkername" style="width: 15%;">
              <a-input v-model.trim="queryParams.checkername" placeholder=""></a-input>
            </a-form-model-item>
            <a-form-model-item label="角色" prop="rolename" style="width: 15%;">
              <a-select v-model.trim="queryParams.rolename" placeholder="">
                <a-select-option value="项目管理员">项目管理员</a-select-option>
                <a-select-option value="项目其他管理员">项目其他管理员</a-select-option>
                <a-select-option value="督导管理员">督导管理员</a-select-option>
              </a-select>
            </a-form-model-item>
<!--            <a-form-model-item v-if="userInfo.usertype==6||userInfo.usertype==5" label="查阅时段" prop="checktime" style="width: 24%;">-->
<!--              <a-range-picker v-model="queryInstallDate"></a-range-picker>-->
<!--            </a-form-model-item>-->
            <a-form-model-item label="查阅时段" prop="checktime" style="width: 25%;">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--              <a-button v-if="userInfo.usertype!=6&&userInfo.usertype!=5" type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="查阅时段" prop="checktime" style="width: 25%;">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner"  style="margin: 10px -30px 10px -30px">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="checktime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="usertype" slot-scope="value,record">{{value==4?'督导管理员':'项目管理员'}}</span>
          <span slot="positiontype" slot-scope="value,record">{{value==1?positionTypes[value].dicvalue:"其他"}}</span>
          <span slot="detail" slot-scope="value,record">
            <a-button type="link" @click="detail(value,record)">详情</a-button>
          </span>
          <span slot="ctotal" slot-scope="value">
            <span>{{value?value:0}}</span>
          </span>
          <span slot="cchecknum" slot-scope="value">
            <span>{{value?value:0}}</span>
          </span>
          <span slot="ccheckrate" slot-scope="value">
            <span>{{value?parseFloat(value*100).toFixed(2):0}}%</span>
          </span>
          <span slot="uncchecknum" slot-scope="value,record">
            <a-button type="link" @click="checkDetail('unchecked',record.useraccount,record.username,record.monitorpointnum)">{{value?value:0}}</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <improve-order-modal :visible.sync="modalVisible" :detailDatas="modalDetailData" @get-operation-result="getTableData"></improve-order-modal>
    <improve-order-check-list :visible.sync="improverOrderModalVisible" :show-type="improverOrderModalShowType" :checkerAccount="checkeraccount" :checkerName="checkername" :MonitorPointNum="monitorpointnum" :Time="time"></improve-order-check-list>
  </page-container>
</template>
<script>
import {mapState} from "vuex";
import moment from "moment";
import pagination from "@/mixins/pagination";
import {findCheckBackStatisticsListByCondition} from "A/wlw";
import {managerTypes, userTypes} from "@/json/wlwhistory";
import deptselect from "@/mixins/deptselect";
import {getCache, getTimeRange} from "U/index";
import ImproveOrderModal from "V/workCheck/eventCheckHistory/ImproveOrderModal";
import ImproveOrderCheckList from "C/modals/ImproveOrderCheckList";
export default {
  name: "Index",
  mixins: [pagination,deptselect],
  components: {
    ImproveOrderModal,
    ImproveOrderCheckList,
  },
  data(){
    return{
      moment,
      showAdvanced: false,
      improverOrderModalVisible:false,
      improverOrderModalShowType:'',
      systemNames:[],
      userTypes,
      queryParams: {
        checkername: '',
        checktime:'',
        userdepsid: '',
        starttime:'',
        endtime:'',
        eventtype:'',
        usertype:'',
        checktype:1,
        isranking:0,
        managetype:'',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      tableColumns: [
        {
          title: '查阅者姓名',
          dataIndex: 'username',
          key: 'username',
          ellipsis: true,
        },
        {
          title: '角色',
          dataIndex: 'usertype',
          key: 'usertype',
          ellipsis: true,
          scopedSlots: { customRender: 'usertype' },
        },
        {
          title: '职位',
          dataIndex: 'managetype',
          key: 'managetype',
          ellipsis: true,
          scopedSlots: { customRender: 'positiontype' },
        },
        {
          title: '所属机构',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '记录数',
          dataIndex: 'ctotal',
          key: 'ctotal',
          ellipsis: true,
          scopedSlots: { customRender: 'ctotal' },
        },
        {
          title: '查阅数',
          dataIndex: 'cchecknum',
          key: 'cchecknum',
          ellipsis: true,
          scopedSlots: { customRender: 'cchecknum' },
        },
        {
          title: '查阅率',
          dataIndex: 'ccheckrate',
          key: 'ccheckrate',
          ellipsis: true,
          scopedSlots: { customRender: 'ccheckrate' },
        },
        {
          title: '未查阅数',
          dataIndex: 'uncchecknum',
          key: 'uncchecknum',
          ellipsis: true,
          scopedSlots: { customRender: 'uncchecknum' },
        },
        {
          title: '详情',
          dataIndex: 'detail',
          key: 'detail',
          ellipsis: true,
          align:'center',
          width: 70,
          scopedSlots: { customRender: 'detail' },
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalDetailData: null,
      userLists:[],
      positionTypes:[],
      managerTypes,
      checkeraccount:'',
      checkername:'',
      monitorpointnum:'',
      time:{
        starttime:'',
        endtime:''
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "品质改进单查阅记录"
    },
    breadcrumb() {
      const pages = [{name:"工作查阅与报告",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
      this.time.starttime=start
      this.time.endtime=end
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.time.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.time.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.positionTypes = dictionary.positionTypes;
    }
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods: {
    handleChange(value) {
      this.queryParams.checkername=value
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    getTableData(firstPage){
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      findCheckBackStatisticsListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.userLists = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    detail(value,record){
      this.modalDetailData=record
      this.modalDetailData.starttime=this.queryParams.starttime
      this.modalDetailData.endtime=this.queryParams.endtime
      this.modalVisible=true
    },
    checkDetail(type,checkeraccount,username,monitorpointnum){
      this.improverOrderModalShowType = type
      this.checkeraccount = checkeraccount
      this.checkername = username
      this.monitorpointnum = monitorpointnum
      this.improverOrderModalVisible = true
    },
    resetQueryParams() {
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
    },
  },
}
</script>
<style scoped>

</style>