<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :footer="false" :width="1280" :centered="true">
    <div class="menu-modal-container">
      <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :scroll="{ x: 1280 }" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
          <span slot="orderstime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
        <span slot="status" slot-scope="value">
             <a-tag :color="value==3?'green':value==2?'yellow':'red'">{{value?processTypes[value-1].value:''}}</a-tag>
          </span>
        <span slot="issendorder" slot-scope="value">
            <span>{{value==1?'是':'否'}}</span>
          </span>
        <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.improveorderid">详情</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
    <add-or-edit-modal :visible.sync="modalDetailVisible" :show-type="modalDetailShowType" :detailData="modalDetailShowData"></add-or-edit-modal>
  </a-modal>
</template>

<script>
import pagination from "@/mixins/pagination";
import {mapGetters, mapState} from "vuex";
import moment from "moment";
import deviceselect from "@/mixins/deviceselect";
import addOrEditModal from "V/supervise/improveOrder/addOrEditModal";
import {getImproveOrderListByCondition} from "A/patrol";
import {processTypes} from "@/json/wlwhistory";
export default {
  name: "improveOrderCheckList",
  mixins: [pagination,deviceselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: ''
    },
    checkerAccount: {
      default: null
    },
    checkerName: {
      default: null
    },
    MonitorPointNum:{
      default: null
    },
    Time:{
      default: null
    }
  },
  components: { addOrEditModal },
  data() {
    return {
      moment,
      modalVisible: false,
      modalShowType:'',
      modalDetailData:null,
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      processTypes,
      userTypes:[],
      systemNames:[],
      queryParams:{
        checkername: '',
        checktime:'',
        userdepsid: '',
        usertype:'',
        checktype:1,
        checkeraccount:'',
        improveordernum:'',
        starttime: '',
        endtime: '',
      },
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '品质改进单编号',
          dataIndex: 'improveordernum',
          key: 'improveordernum',
          ellipsis: true,
        },
        {
          title: '接单时间',
          dataIndex: 'orderstime',
          key: 'orderstime',
          ellipsis: true,
          scopedSlots: { customRender: 'orderstime' },
        },
        {
          title: '完成标志',
          dataIndex: 'status',
          key: 'status',
          ellipsis: true,
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      tableData: [],
      tableLoading: false,
      modalDetailVisible: false,
      modalDetailShowType:'',
      modalDetailShowData:null,
      isclickrow:false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadBasemap']),
    modalTitle() {
      if (this.showType == 'unchecked') {
        return '未查阅品质改进单条目'
      } else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.getTableData(true);
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        checkeraccount: this.checkerAccount,
        checkername: this.checkerName,
        monitorpointnum: this.MonitorPointNum,
        ischecked: 0,
        ...this.Time,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getImproveOrderListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            if(!this.isclickrow) {
              this.modalDetailShowType = "detail";
              this.getImproveOrderByCondition(record.eventrecordid)
            }
          },
        },
      }
    },
    showModal(type, value) {
      this.modalDetailShowType = type;
      this.getImproveOrderByCondition(value)
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let improveorderid = arr[1];
      if(type == 'delete') {
      }else{
        this.showModal(type, improveorderid)
      }
    },
    getImproveOrderByCondition(improveorderid){
      let params = {
        improveorderid:improveorderid,
      };
      getImproveOrderListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.modalDetailShowData = res.item[0];
          this.modalDetailShowData.improveorderid=res.item[0].improveorderid
          this.modalDetailShowData.orderstime=this.modalDetailShowData.orderstime?moment(this.modalDetailShowData.orderstime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
          this.modalDetailShowData.analysistime=this.modalDetailShowData.analysistime?moment(this.modalDetailShowData.analysistime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
          this.modalDetailShowData.improvetime=this.modalDetailShowData.improvetime?moment(this.modalDetailShowData.improvetime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
          this.modalDetailShowData.scheduledtime=this.modalDetailShowData.scheduledtime?moment(this.modalDetailShowData.scheduledtime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
          this.modalDetailShowData.verificationtime=this.modalDetailShowData.verificationtime?moment(this.modalDetailShowData.verificationtime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
          this.modalDetailVisible = true;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
  },
}
</script>

<style scoped>

</style>