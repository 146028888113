<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :footer="false" :width="1280" :centered="true">
    <div class="menu-modal-container">
      <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :scroll="{ x: 1280 }" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
        <span slot="eventstarttime" slot-scope="value">
          <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
            <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-tooltip>
        </span>
        <span slot="checktime" slot-scope="value">
          <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
            <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-tooltip>
        </span>
        <span slot="systemnameid" slot-scope="value,record">{{systemNamesMap[value]?systemNamesMap[value]:''}}</span>
        <span slot="writetime" slot-scope="value">
          <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
            <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-tooltip>
        </span>
        <span slot="arrivetime" slot-scope="value">
          <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
            <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-tooltip>
        </span>
        <span slot="timerange" slot-scope="value,record">
            <span>{{value?value+"分钟":""}}</span>
          </span>
        <span slot="feedback" slot-scope="value">
            <span>{{feedbackList[value-1]?feedbackList[value-1].dicvalue:''}}</span>
          </span>
        <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.eventrecordid">详情</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
    <a-modal :visible="detailVisible" :title="title" @cancel="detailVisible=false" :footer="null" :width="1200">
      <a-form-model ref="queryForm" :model="detailParams" layout="inline" class="query-form">
        <a-form-model-item label="事件编号" prop="eventnum" style="width: 30%">
          <a-input placeholder="系统自动生成" style="width: 200px;" v-model="detailParams.eventnum" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="系统名称(场景)" prop="systemnameid" style="width: 30%">
          <a-select default-value="" v-model="detailParams.systemnameid" placeholder="" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in systemNames" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="报警时间" prop="eventstarttime" style="width: 30%">
          <a-date-picker show-time placeholder="" v-model="detailParams.eventstarttime" style="width: 200px;" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype==902" label="事件类型" prop="eventcode" style="width: 30%">
          <a-select v-model="detailParams.eventcode" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in abnormalTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="报警类型" prop="eventcode" style="width: 30%">
          <a-select v-model="detailParams.eventcode" placeholder="" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in alarmTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="重大影响" prop="biginfluence" style="width: 30%">
          <a-select v-model="detailParams.biginfluence" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in bigInfluences" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype==902" label="管理类型" prop="managetype" style="width: 30%">
          <a-input v-model.trim="detailParams.managetype" placeholder="" style="width: 200px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype==902" label="发生时间" prop="eventstarttime" style="width: 30%">
          <a-date-picker show-time placeholder="" v-model="detailParams.eventstarttime" style="width: 200px;" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.biginfluence!=1&&detailParams.plannedwork!=1" label="特别事件报告编号" prop="specialeventnum" style="width: 30%">
          <a-input v-model="detailParams.specialeventnum" placeholder="请输入" style="width: 200px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="编号/位置" prop="location" style="width: 30%">
          <a-textarea v-model="detailParams.location" placeholder="" cols="24" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="到场人员" prop="arriveman" style="width: 30%">
          <a-input v-model="detailParams.arriveman" placeholder="" style="width: 200px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="到场时间" prop="arrivetime" style="width: 30%">
          <a-date-picker show-time placeholder="" v-model="detailParams.arrivetime" style="width: 200px;" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="确警时长" prop="timerange" style="width: 30%">
          <a-input v-model="detailParams.timerange" addon-after="分钟" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype==902&&detailParams.eventcode=='6'" label="设备类型" prop="devicetype" style="width: 30%">
          <a-cascader :disabled="true" :field-names="{ label: 'devicename', value: 'devicecode', children: 'children' }" :options="deviceOptions" v-model="deviceCascaderSelected" :load-data="deviceOnLoadChildren" placeholder="请选择设备类型" :change-on-select="true"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="报事/报修" prop="reports" style="width: 30%">
          <a-select v-model="detailParams.reports" placeholder="请选择" style="width: 200px;" :disabled="true">
            <a-select-option value="无">无</a-select-option>
            <a-select-option value="报事">报事</a-select-option>
            <a-select-option value="报修">报修</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="报事/报修单号" prop="repairorder" style="width: 30%">
          <a-input v-model="detailParams.repairorder" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="计划性作业" prop="plannedwork" style="width: 30%">
          <a-select v-model="detailParams.plannedwork" placeholder="请选择" style="width: 125px" :disabled="true">
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="到场反馈" prop="feedback" style="width: 30%">
          <a-select v-model="detailParams.feedback" placeholder="" style="width: 200px" :disabled="true">
            <a-select-option v-for="(item, index) in feedbackList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="情况描述" prop="description" style="width: 30%">
          <a-textarea v-model="detailParams.description" placeholder="" cols="32" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="处理措施" prop="measure" style="width: 30%">
          <a-textarea v-model="detailParams.measure" placeholder="" cols="32" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="记录人" prop="recordman" style="width: 30%">
          <a-input v-model="detailParams.recordman" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="记录时间" prop="writetime" style="width: 30%">
          <a-date-picker v-model="detailParams.writetime" show-time placeholder="" :disabled="true" style="width: 200px;"></a-date-picker>
        </a-form-model-item>
        <a-divider />
        <a-form-model-item label="跟进结果" prop="followreturn" style="width: 30%">
          <a-textarea v-model="detailParams.followreturn" placeholder="" cols="32" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="完成标志" prop="eventstatus" style="width: 30%">
          <a-select default-value="" v-model="detailParams.eventstatus" style="width: 200px" :disabled="true">
            <a-select-option v-for="(item, index) in finishSigns" :key="index" :value="item.key">{{item.value}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="确认人" prop="confirmman" style="width: 30%">
          <a-input v-model="detailParams.confirmman" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="确认时间" prop="confirmtime" style="width: 30%">
          <a-date-picker v-model="detailParams.confirmtime" show-time placeholder="" :disabled="true" style="width: 200px;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item label="处理时长" prop="processtime" style="width: 30%">
          <a-input v-model="detailParams.processtime" addon-after="分钟" placeholder="" :disabled="true" style="width: 200px"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-modal>
</template>
<script>
import pagination from "@/mixins/pagination";
import {mapGetters, mapState} from "vuex";
import {getEventrecordListByCondition, getUnCheckEventrecordListByCondition} from "A/wlw";
import moment from "moment";
import {getCache, getItemFromArrayByKey} from "U/index";
import deviceselect from "@/mixins/deviceselect";
import {finishSigns} from "@/json/wlwhistory";
export default {
  name: "EventCheckList",
  mixins: [pagination,deviceselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: ''
    },
    checkerAccount: {
      default: null
    },
    checkerName: {
      default: null
    },
    MonitorPointNum:{
      default: null
    },
    Time:{
      default: null
    }
  },
  components: {},
  data() {
    return {
      moment,
      title:"未查阅条目详情",
      detailVisible:false,
      detailParams:{},
      systemNamesMap: [],
      finishSigns,
      systemNames:[],
      alarmTypes:[],
      feedbackList:[],
      bigInfluences:[],
      deviceCascaderSelected:[],
      modalVisible: false,
      queryParams: {
        monitorpointname: '',
        eventnum: '',
        eventcode: '',
        starttime: '',
        endtime: '',
        eventtype: '',
        eventstatus: '',
      },
      alarmColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          align: 'center',
          ellipsis: true,
          width: 200
        },
        {
          title: '事件编号',
          dataIndex: 'eventnum',
          key: 'eventnum',
          align: 'center',
          ellipsis: true,
          width: 100
        },
        {
          title: '系统名称(场景)',
          dataIndex: 'systemnameid',
          key: 'systemnameid',
          align: 'center',
          ellipsis: true,
          scopedSlots: {customRender: 'systemnameid'},
          width: 150
        },
        {
          title: '报警类型',
          dataIndex: 'eventname',
          key: 'eventname',
          align: 'center',
          ellipsis: true,
          width: 120
        },
        {
          title: '查阅时间',
          dataIndex: 'checktime',
          key: 'checktime',
          align: 'center',
          ellipsis: true,
          scopedSlots: {customRender: 'checktime'},
          width: 180
        },
        {
          title: '报警时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          align: 'center',
          ellipsis: true,
          scopedSlots: {customRender: 'eventstarttime'},
          width: 180
        },
        {
          title: '记录时间',
          dataIndex: 'writetime',
          key: 'writetime',
          align: 'center',
          ellipsis: true,
          scopedSlots: {customRender: 'writetime'},
          width: 180
        },
        {
          title: '编号/位置',
          dataIndex: 'location',
          key: 'location',
          ellipsis: true,
          align: "center",
          width: 100
        },
        {
          title: '到场人员',
          dataIndex: 'arriveman',
          key: 'arriveman',
          align: 'center',
          ellipsis: true,
          width: 80
        },
        {
          title: '到场时间',
          dataIndex: 'arrivetime',
          key: 'arrivetime',
          align: 'center',
          ellipsis: true,
          scopedSlots: {customRender: 'arrivetime'},
          width: 180
        },
        {
          title: '到场时长',
          dataIndex: 'timerange',
          key: 'timerange',
          align: 'center',
          ellipsis: true,
          scopedSlots: {customRender: 'timerange'},
          width: 100
        },
        {
          title: '到场反馈',
          dataIndex: 'feedback',
          key: 'feedback',
          ellipsis: true,
          align: "center",
          scopedSlots: {customRender: 'feedback'},
          width: 80
        },
        {
          title: '处理措施',
          dataIndex: 'measure',
          key: 'measure',
          align: 'center',
          ellipsis: true,
          width: 80
        },
        {
          title: '报事/报修单号',
          dataIndex: 'repairorder',
          key: 'repairorder',
          align: 'center',
          ellipsis: true,
          width: 80
        },
        // {
        //   title: '查阅',
        //   dataIndex: 'checkrecord',
        //   key: 'checkrecord',
        //   align: 'center',
        //   ellipsis: true,
        //   width: 80,
        //   scopedSlots: { customRender: 'checkrecord' },
        // },
        // {
        //   title: '查阅记录',
        //   dataIndex: 'checkrecords',
        //   key: 'checkrecords',
        //   align: 'center',
        //   ellipsis: true,
        //   width: 80,
        //   scopedSlots: { customRender: 'checkrecords' },
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      abnormalColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          align: 'center',
          ellipsis: true,
          width: 200
        },
        {
          title: '事件编号',
          dataIndex: 'eventnum',
          key: 'eventnum',
          align: 'center',
          ellipsis: true,
          width: 100
        },
        {
          title: '管理类型',
          dataIndex: 'managetype',
          key: 'managetype',
          align: 'center',
          ellipsis: true,
          scopedSlots: {customRender: 'managetype'},
          width: 150
        },
        {
          title: '查阅时间',
          dataIndex: 'checktime',
          key: 'checktime',
          align: 'center',
          ellipsis: true,
          scopedSlots: {customRender: 'checktime'},
          width: 180
        },
        {
          title: '记录时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          align: 'center',
          ellipsis: true,
          scopedSlots: {customRender: 'eventstarttime'},
          width: 180
        },
        {
          title: '事件类型',
          dataIndex: 'eventname',
          key: 'eventname',
          align: 'center',
          ellipsis: true,
          width: 120
        },
        {
          title: '编号/位置',
          dataIndex: 'location',
          key: 'location',
          ellipsis: true,
          align: "center",
          width: 100
        },
        {
          title: '情况描述',
          dataIndex: 'description',
          key: 'description',
          align: 'center',
          ellipsis: true,
          width: 150
        },
        {
          title: '处理措施',
          dataIndex: 'measure',
          key: 'measure',
          align: 'center',
          ellipsis: true,
          width: 80
        },
        {
          title: '报事/报修单号',
          dataIndex: 'repairorder',
          key: 'repairorder',
          align: 'center',
          ellipsis: true,
          width: 80
        },
        // {
        //   title: '查阅',
        //   dataIndex: 'checkrecord',
        //   key: 'checkrecord',
        //   align: 'center',
        //   ellipsis: true,
        //   width: 80,
        //   scopedSlots: { customRender: 'checkrecord' },
        // },
        // {
        //   title: '查阅记录',
        //   dataIndex: 'checkrecords',
        //   key: 'checkrecords',
        //   align: 'center',
        //   ellipsis: true,
        //   width: 80,
        //   scopedSlots: { customRender: 'checkrecords' },
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      tableColumns: [],
      tableData: [],
      tableLoading: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadBasemap']),
    modalTitle() {
      if (this.showType == 'alarm') {
        return '未查阅报警条目'
      } else if (this.showType == 'abnormal') {
        return '未查阅异常条目'
      } else if (this.showType == 'upalarm'||this.showType == 'upabnormal') {
        return '超时查阅条目'
      } else {
        return '';
      }
    }
  },
  watch: {
    deviceCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.devicecode = val[val.length-1];
      }else {
        this.queryParams.devicecode = '';
      }
    },
    visible(val) {
      if (val) {
        this.modalVisible = true;
        let dictionary = getCache('dictionary', true);
        if (dictionary) {
          this.bigInfluences = dictionary.bigInfluences;
          this.alarmTypes = dictionary.alarmTypes;
          this.alarmTypesMap = dictionary.alarmTypesMap;
          this.systemNames = dictionary.systemNames;
          this.systemNamesMap = dictionary.systemNamesMap;
          this.feedbackList = dictionary.feedbackList;
          this.abnormalTypes = dictionary.abnormalTypes;
          this.abnormalTypesMap = dictionary.abnormalTypesMap;
          this.equipmentTypes = dictionary.equipmentTypes;
          this.manageTypes = dictionary.manageTypes;
          this.recordTypes = dictionary.recordTypes;
        }
        this.getTableData(true);
      }
    },
    modalVisible(val) {
      if (!val) {
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            if(!this.isclickrow){
              record.eventstarttime=record.eventstarttime?moment(record.eventstarttime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
              record.confirmtime=record.confirmtime?moment(record.confirmtime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
              record.writetime=record.writetime?moment(record.writetime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
              record.arrivetime=record.arrivetime?moment(record.arrivetime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
              record.biginfluence=record.biginfluence?record.biginfluence.toString():null
              record.systemnameid=record.systemnameid?record.systemnameid.toString():null
              record.feedback=record.feedback?record.feedback.toString():null
              if (record.devicecode) {
                this.initDeviceOptionsByDevicecode(record.devicecode)
              }else{
                this.deviceCascaderSelected=[]
              }
              if (this.showType == "upalarm"||this.showType == "upabnormal") {
                this.title = "超时查阅条目详情"
              }
              this.detailParams=record
              this.detailVisible=true
            }
          },
        },
      }
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      if (this.showType == "alarm"||this.showType == "upalarm") {
        this.tableColumns = this.alarmColumns
      } else if (this.showType == "abnormal"||this.showType == "upabnormal") {
        this.tableColumns = this.abnormalColumns
      }
      let params = {
        eventtype:this.showType == "upalarm"||this.showType == "alarm"?901:902,
        checkeraccount:this.checkerAccount,
        checkername:this.checkerName,
        monitorpointnum:this.MonitorPointNum,
        history:true,
        batterycar:true,
        ischecked:0,
        ...this.Time,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      if(this.showType == "alarm" ||this.showType == "abnormal"){
        getEventrecordListByCondition(params).then(res => {
          this.tableLoading = false;
          if(res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }else{
        getUnCheckEventrecordListByCondition(params).then(res => {
          this.tableLoading = false;
          if (res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let eventrecordid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'eventrecordid', eventrecordid);
      if(type == 'delete') {
        this.deleteConfirm(eventrecordid, record)
      }else if(type == 'detail'){
        record.eventstarttime=record.eventstarttime?moment(record.eventstarttime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
        record.confirmtime=record.confirmtime?moment(record.confirmtime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
        record.writetime=record.writetime?moment(record.writetime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
        record.arrivetime=record.arrivetime?moment(record.arrivetime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
        record.biginfluence=record.biginfluence?record.biginfluence.toString():null
        record.systemnameid=record.systemnameid?record.systemnameid.toString():null
        record.feedback=record.feedback?record.feedback.toString():null
        if (record.devicecode) {
          this.initDeviceOptionsByDevicecode(record.devicecode)
        }else{
          this.deviceCascaderSelected=[]
        }
        this.detailParams=record
        this.detailVisible=true
      }
    },
  },
}
</script>
<style scoped>

</style>