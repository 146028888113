<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <a-tabs default-active-key="1">
          <a-tab-pane key="1" tab="报警历史查阅记录">
            <alarm-check-history></alarm-check-history>
          </a-tab-pane>
          <a-tab-pane key="2" tab="异常历史查阅记录">
            <abnormal-check-history></abnormal-check-history>
          </a-tab-pane>
          <a-tab-pane key="3" tab="品质改进单查阅记录">
            <improve-order-check-history></improve-order-check-history>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </page-container>
</template>

<script>
import {mapState} from "vuex";
import AlarmCheckHistory from "V/workCheck/eventCheckHistory/AlarmCheckHistory";
import AbnormalCheckHistory from "V/workCheck/eventCheckHistory/AbnormalCheckHistory";
import ImproveOrderCheckHistory from "V/workCheck/eventCheckHistory/ImproveOrderCheckHistory";
export default {
  name: "eventCheckHistory",
  components:{
    AlarmCheckHistory,
    AbnormalCheckHistory,
    ImproveOrderCheckHistory,
  },
  data(){
    return{
      showAdvanced: false,
      queryParams: {},
      userdepidCascaderSelected: [],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "查阅情况分析报表"
    },
    breadcrumb() {
      const pages = [{name:"工作查阅与报告",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
  },
  methods: {
  },
}
</script>

<style scoped>

</style>