<template>
  <page-container :title="pageTitle">
    <div class="box-container">
      <div class="box-container-inner" style="margin: -30px -30px -25px -30px">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 20%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="姓名" prop="checkername" style="width: 15%;">
              <a-input v-model.trim="queryParams.checkername" placeholder=""></a-input>
<!--              <a-select v-model.trim="queryParams.checkername" show-search :filter-option="filterOption" @change="handleChange" :dropdownMatchSelectWidth=false>-->
<!--                <a-select-option v-for="(item, index) in userLists" :key="index" :value="item.username">{{item.username}}</a-select-option>-->
<!--              </a-select>-->
            </a-form-model-item>
            <a-form-model-item label="角色" prop="rolename" style="width: 15%;">
              <a-select v-model.trim="queryParams.rolename" placeholder="">
                <a-select-option value="项目管理员">项目管理员</a-select-option>
                <a-select-option value="项目其他管理员">项目其他管理员</a-select-option>
                <a-select-option value="督导管理员">督导管理员</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="查阅时段" prop="checktime" style="width: 25%;">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]">
              </a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--              <a-button v-if="userInfo.usertype!=6&&userInfo.usertype!=5" type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="查阅时段" prop="checktime" style="width: 25%;">
              <a-range-picker v-model="queryInstallDate"></a-range-picker>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: 10px -30px 10px -30px">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="checktime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
<!--          <span slot="usertype" slot-scope="value,record">{{value==4?'督导管理员':'项目管理员'}}</span>-->
<!--          <span slot="positiontype" slot-scope="value,record">{{value==1?positionTypes[value].dicvalue:"其他"}}</span>-->
          <span slot="detail" slot-scope="value,record">
            <a-button type="link" @click="detail(value,record)">详情</a-button>
          </span>
          <span slot="total" slot-scope="value">
            <span>{{value?value:0}}</span>
          </span>
          <span slot="checknum" slot-scope="value">
            <span>{{value?value:0}}</span>
          </span>
          <span slot="checkrate" slot-scope="value">
            <span>{{value?parseFloat(value*100).toFixed(2):0}}%</span>
          </span>
          <span slot="unchecknum" slot-scope="value,record">
            <a-button type="link" @click="checkDetail('alarm',record.useraccount,record.username,record.monitorpointnum)">{{value?value:0}}</a-button>
          </span>
          <span slot="pchecknum" slot-scope="value,record">
            <a-button type="link" @click="checkDetail('upalarm',record.useraccount,record.username,record.monitorpointnum)">{{value?value:0}}</a-button>
          </span>
          <span slot="pcheckrate" slot-scope="value">
            <span>{{value?parseFloat(value*100).toFixed(2):0}}%</span>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <event-check-list :visible.sync="eventModalVisible" :show-type="eventModalShowType" :checkerAccount="checkeraccount" :checkerName="checkername" :MonitorPointNum="monitorpointnum" :Time="time"></event-check-list>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>
<script>
import {mapState} from "vuex";
import moment from "moment";
import pagination from "@/mixins/pagination";
import {findCheckBackStatisticsListByCondition} from "A/wlw";
import {managerTypes, userTypes} from "@/json/wlwhistory";
import deptselect from "@/mixins/deptselect";
import {getCache, getTimeRange} from "U/index";
import addOrEditModal from "V/workCheck/eventCheckHistory/addOrEditModal";
import EventCheckList from "C/modals/EventCheckList";
import {exportCheckEventRecordExcel} from "A/jcgn";
export default {
  name: "Index",
  mixins: [pagination,deptselect],
  components: {
    addOrEditModal,
    EventCheckList,
  },
  data(){
    return{
      moment,
      showAdvanced: false,
      systemNames:[],
      userTypes,
      eventModalVisible:false,
      eventModalShowType:'',
      queryParams: {
        checkername: '',
        checktime:'',
        userdepsid: '',
        starttime:'',
        endtime:'',
        eventtype:901,
        usertype:'',
        checktype:0,
        isranking:0,
        querytype:1,
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      tableColumns: [
        // {
        //   title: '项目名称',
        //   dataIndex: 'monitorpointname',
        //   key: 'monitorpointname',
        //   ellipsis: true,
        // },
        {
          title: '查阅者姓名',
          dataIndex: 'username',
          key: 'username',
          ellipsis: true,
        },
        {
          title: '角色',
          dataIndex: 'rolename',
          key: 'rolename',
          ellipsis: true,
        },
        // {
        //   title: '职位',
        //   dataIndex: 'managetype',
        //   key: 'managetype',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'positiontype' },
        // },
        {
          title: '所属机构',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '记录数',
          dataIndex: 'total',
          key: 'total',
          ellipsis: true,
          scopedSlots: { customRender: 'total' },
        },
        {
          title: '查阅数',
          dataIndex: 'checknum',
          key: 'checknum',
          ellipsis: true,
          scopedSlots: { customRender: 'checknum' },
        },
        {
          title: '查阅率',
          dataIndex: 'checkrate',
          key: 'checkrate',
          ellipsis: true,
          scopedSlots: { customRender: 'checkrate' },
        },
        {
          title: '未查阅数',
          dataIndex: 'unchecknum',
          key: 'unchecknum',
          ellipsis: true,
          scopedSlots: { customRender: 'unchecknum' },
        },
        {
          title: '超时查阅数',
          dataIndex: 'pchecknum',
          key: 'pchecknum',
          ellipsis: true,
          scopedSlots: { customRender: 'pchecknum' },
        },
        {
          title: '超时查阅率',
          dataIndex: 'pcheckrate',
          key: 'pcheckrate',
          ellipsis: true,
          scopedSlots: { customRender: 'pcheckrate' },
        },
        {
          title: '详情',
          dataIndex: 'detail',
          key: 'detail',
          ellipsis: true,
          align:'center',
          width: 70,
          scopedSlots: { customRender: 'detail' },
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      userLists:[],
      positionTypes:[],
      managerTypes,
      checkeraccount:'',
      checkername:'',
      monitorpointnum:'',
      time:{
        starttime:'',
        endtime:''
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "报警历史查阅记录"
    },
    breadcrumb() {
      const pages = [{name:"工作查阅与报告",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
      this.time.starttime=start
      this.time.endtime=end
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.time.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.time.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.positionTypes = dictionary.positionTypes
    }
    this.getTableData()
    this.initDeptOptionsAll()
  },
  methods: {
    handleChange(value) {
      this.queryParams.checkername=value
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    getTableData(firstPage){
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      findCheckBackStatisticsListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.userLists = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    detail(value,record){
      this.modalDetailData=record
      this.modalDetailData.starttime=this.queryParams.starttime
      this.modalDetailData.endtime=this.queryParams.endtime
      this.modalShowType='alarm'
      this.modalVisible=true
    },
    resetQueryParams() {
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
    },
    checkDetail(type,useraccount,username,monitorpointnum) {
      this.eventModalShowType = type
      this.checkeraccount = useraccount
      this.checkername = username
      this.monitorpointnum = monitorpointnum
      this.eventModalVisible = true
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportCheckEventRecord()
      }).catch(()=>{
      });
    },
    exportCheckEventRecord(){
      let params = {
        ...this.queryParams,
      }
      this.showLoading();
      exportCheckEventRecordExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    }
  },
}
</script>
<style scoped>

</style>