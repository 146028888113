<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="1300" :centered="true">
    <template slot="footer">
      <a-button @click="modalVisible=false">返回</a-button>
    </template>
    <div class="simple-query">
      <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
        <a-form-model-item label="查阅时段" prop="checktime" style="width: 40%;">
          <a-range-picker v-model="queryInstallDate"></a-range-picker>
        </a-form-model-item>
        <a-form-model-item label="事件编号" prop="eventnum" style="width: 25%">
          <a-input v-model="queryParams.eventnum"></a-input>
        </a-form-model-item>
        <div class="query-btns" style="float: right;width: 15%;margin-top: 9px">
          <a-button @click="resetQueryParams">重置</a-button>
          <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
        </div>
      </a-form-model>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: 10px -30px 10px -30px">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :scroll="{ x: 1200}" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="systemnameid" slot-scope="value,record">{{systemNames[value-1]?systemNames[value-1].value:''}}</span>
          <span slot="eventstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="checktime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="usertype" slot-scope="value,record">{{value==4?'督导管理员':'项目管理员'}}</span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.eventrecordid">详情</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal :visible="detailVisible" title="异常情况详情" @cancel="detailVisible=false" :footer="null" :width="1200">
      <a-form-model ref="queryForm" :model="detailParams" layout="inline" class="query-form">
        <a-form-model-item label="事件编号" prop="eventnum" style="width: 30%">
          <a-input placeholder="系统自动生成" style="width: 200px;" v-model="detailParams.eventnum" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="系统名称" prop="systemnameid" style="width: 30%">
          <a-select default-value="" v-model="detailParams.systemnameid" placeholder="" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in systemNames" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype==902" label="事件类型" prop="eventcode" style="width: 30%">
          <a-select v-model="detailParams.eventcode" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in abnormalTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="报警类型" prop="eventcode" style="width: 30%">
          <a-select v-model="detailParams.eventcode" placeholder="" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in alarmTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="重大影响" prop="biginfluence" style="width: 30%">
          <a-select v-model="detailParams.biginfluence" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in bigInfluences" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype==902" label="管理类型" prop="managetype" style="width: 30%">
          <a-input v-model.trim="detailParams.managetype" placeholder="" style="width: 200px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype==902" label="发生时间" prop="eventstarttime" style="width: 30%">
          <a-date-picker show-time placeholder="" v-model="detailParams.eventstarttime" style="width: 200px;" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item label="编号/位置" prop="location" style="width: 30%">
          <a-textarea v-model="detailParams.location" placeholder="" cols="24" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="到场人员" prop="arriveman" style="width: 30%">
          <a-input v-model="detailParams.arriveman" placeholder="" style="width: 200px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="到场时间" prop="arrivetime" style="width: 30%">
          <a-date-picker show-time placeholder="" v-model="detailParams.arrivetime" style="width: 200px;" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="确警时长" prop="timerange" style="width: 30%">
          <a-input v-model="detailParams.timerange" addon-after="分钟" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype==902&&detailParams.eventcode=='6'" label="设备类型" prop="devicetype" style="width: 30%">
          <a-cascader :disabled="true" :field-names="{ label: 'devicename', value: 'devicecode', children: 'children' }" :options="deviceOptions" v-model="deviceCascaderSelected" :load-data="deviceOnLoadChildren" placeholder="请选择设备类型" :change-on-select="true"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="报事/报修" prop="reports" style="width: 30%">
          <a-select v-model="detailParams.reports" placeholder="请选择" style="width: 200px;" :disabled="true">
            <a-select-option value="无">无</a-select-option>
            <a-select-option value="报事">报事</a-select-option>
            <a-select-option value="报修">报修</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="报事/报修单号" prop="repairorder" style="width: 30%">
          <a-input v-model="detailParams.repairorder" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="计划性作业" prop="plannedwork" style="width: 30%">
          <a-select v-model="detailParams.plannedwork" placeholder="请选择" style="width: 125px" :disabled="true">
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="到场反馈" prop="feedback" style="width: 30%">
          <a-select v-model="detailParams.feedback" placeholder="" style="width: 200px" :disabled="true">
            <a-select-option v-for="(item, index) in feedbackList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="情况描述" prop="description" style="width: 30%">
          <a-textarea v-model="detailParams.description" placeholder="" cols="32" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="处理措施" prop="measure" style="width: 30%">
          <a-textarea v-model="detailParams.measure" placeholder="" cols="32" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-divider />
        <a-form-model-item label="跟进结果" prop="followreturn" style="width: 30%">
          <a-textarea v-model="detailParams.followreturn" placeholder="" cols="32" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="完成标志" prop="eventstatus" style="width: 30%">
          <a-select default-value="" v-model="detailParams.eventstatus" style="width: 200px" :disabled="true">
            <a-select-option v-for="(item, index) in finishSigns" :key="index" :value="item.key">{{item.value}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="确认人" prop="confirmman" style="width: 30%">
          <a-input v-model="detailParams.confirmman" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="确认时间" prop="confirmtime" style="width: 30%">
          <a-date-picker v-model="detailParams.confirmtime" show-time placeholder="" :disabled="true" style="width: 200px;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item label="处理时长" prop="processtime" style="width: 30%">
          <a-input v-model="detailParams.processtime" addon-after="分钟" placeholder="" :disabled="true" style="width: 200px"></a-input>
        </a-form-model-item>
        <a-form-model-item label="记录时间" prop="writetime" style="width: 30%">
          <a-date-picker v-model="detailParams.writetime" show-time placeholder="" :disabled="true" style="width: 200px;"></a-date-picker>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-modal>
</template>
<script>
import moment from "moment";
import {mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCheckBackListByCondition, getEventrecordListByCondition} from "A/wlw";
import deptselect from "@/mixins/deptselect";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import deviceselect from "@/mixins/deviceselect";
import {finishSigns} from "@/json/wlwhistory";
export default {
  mixins: [pagination,deptselect,deviceselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'alarm'
    },
    detailData: {
      default: null
    },
  },
  data() {
    return {
      moment,
      modalVisible: false,
      modalShowType:'',
      detailVisible:false,
      detailParams:{},
      systemNamesMap: [],
      finishSigns,
      systemNames:[],
      alarmTypes:[],
      feedbackList:[],
      bigInfluences:[],
      deviceCascaderSelected:[],
      modalDetailData:null,
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      userTypes:[],
      queryParams:{
        checkername: '',
        checktime:'',
        userdepsid: '',
        starttime:'',
        endtime:'',
        eventnum:'',
        eventtype:'',
        usertype:'',
        checktype:0,
        checkeraccount:'',
      },
      tableColumns: [
        {
          title: '查阅者姓名',
          dataIndex: 'checkername',
          key: 'checkername',
          ellipsis: true,
          width:80,
        },
        {
          title: '联系电话',
          dataIndex: 'checkerphone',
          key: 'checkerphone',
          ellipsis: true,
          width: 100
        },
        {
          title: '查阅时间',
          dataIndex: 'checktime',
          key: 'checktime',
          ellipsis: true,
          scopedSlots: { customRender: 'checktime' },
          width: 180
        },
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
          width: 200
        },
        {
          title: '事件编号',
          dataIndex: 'eventnum',
          key: 'eventnum',
          ellipsis: true,
          width: 100
        },
        {
          title: '事件类型',
          dataIndex: 'eventname',
          key: 'eventname',
          ellipsis: true,
          width: 100
        },
        {
          title: '事件发生时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          ellipsis: true,
          scopedSlots: { customRender: 'eventstarttime' },
          width: 180
        },
        {
          title: '角色',
          dataIndex: 'usertype',
          key: 'usertype',
          ellipsis: true,
          scopedSlots: { customRender: 'usertype' },
          width: 100
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      tableData: [],
      tableLoading: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      if(this.showType == 'alarm') {
        return '报警历史查阅记录'
      }else if(this.showType == 'abnormal') {
        return '异常历史查阅记录'
      }else {
        return '';
      }
    },
    modalTitle() {
      if(this.showType == 'alarm') {
        return '报警历史查阅记录'
      }else if(this.showType == 'abnormal') {
        return '异常历史查阅记录'
      }else {
        return '';
      }
    }
  },
  watch: {
    deviceCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.devicecode = val[val.length-1];
      }else {
        this.queryParams.devicecode = '';
      }
    },
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.queryParams.starttime=this.detailData.starttime
        this.queryParams.endtime=this.detailData.endtime
        let dictionary = getCache('dictionary', true);
        if (dictionary) {
          this.bigInfluences = dictionary.bigInfluences;
          this.alarmTypes = dictionary.alarmTypes;
          this.alarmTypesMap = dictionary.alarmTypesMap;
          this.systemNames = dictionary.systemNames;
          this.systemNamesMap = dictionary.systemNamesMap;
          this.feedbackList = dictionary.feedbackList;
          this.abnormalTypes = dictionary.abnormalTypes;
          this.abnormalTypesMap = dictionary.abnormalTypesMap;
          this.equipmentTypes = dictionary.equipmentTypes;
          this.manageTypes = dictionary.manageTypes;
          this.recordTypes = dictionary.recordTypes;
        }
        this.getTableData();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    this.initDeptOptionsAll()
  },
  methods: {
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            if(!this.isclickrow){
              this.getEventRecordData(record)
            }
          },
        },
      }
    },
    getEventRecordData(record) {
      this.tableLoading = true;
      let params = {
        eventrecordid:record.eventrecordid,
        history:true,
        batterycar:true,
        // pageno: this.currentPage,
        // pagesize: this.pagination.pageSize
      };
      getEventrecordListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          res.item[0].eventstarttime=res.item[0].eventstarttime?moment(res.item[0].eventstarttime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
          res.item[0].confirmtime=res.item[0].confirmtime?moment(res.item[0].confirmtime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
          res.item[0].writetime=res.item[0].writetime?moment(res.item[0].writetime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
          res.item[0].arrivetime=res.item[0].arrivetime?moment(res.item[0].arrivetime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
          res.item[0].biginfluence=res.item[0].biginfluence?res.item[0].biginfluence.toString():null
          res.item[0].systemnameid=res.item[0].systemnameid?res.item[0].systemnameid.toString():null
          res.item[0].feedback=res.item[0].feedback?res.item[0].feedback.toString():null
          if (res.item[0].devicecode) {
            this.initDeviceOptionsByDevicecode(res.item[0].devicecode)
          }
          this.detailParams=res.item[0]
          this.detailVisible=true
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    getTableData(firstPage){
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      this.queryParams.checkeraccount=this.detailData.useraccount
      this.showType=='alarm'?this.queryParams.eventtype=901:this.queryParams.eventtype=902
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getCheckBackListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
    },
    resetDatas() {
      this.$refs.queryForm.resetFields();
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let eventrecordid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'eventrecordid', eventrecordid);
      if(type == 'delete') {
        this.deleteConfirm(eventrecordid, record)
      }else if(type == 'detail'){
        this.getEventRecordData(record)
      }
    },
  }
}
</script>