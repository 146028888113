<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="1300" :centered="true">
    <template slot="footer">
      <a-button @click="modalVisible=false">返回</a-button>
    </template>
    <div class="simple-query">
      <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
        <a-form-model-item label="查阅时段" prop="checktime" style="width: 40%;">
          <a-range-picker v-model="queryInstallDate"></a-range-picker>
        </a-form-model-item>
        <a-form-model-item label="品质改进单号" prop="improveordernum" style="width: 25%">
          <a-input v-model="queryParams.improveordernum"></a-input>
        </a-form-model-item>
        <div class="query-btns" style="float: right;width: 15%;margin-top: 9px">
          <a-button @click="resetQueryParams">重置</a-button>
          <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
        </div>
      </a-form-model>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: 10px -30px 10px -30px">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="eventstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="checktime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="usertype" slot-scope="value,record">{{value==4?'督导管理员':'项目管理员'}}</span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.eventrecordid">详情</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalDetailVisible" :show-type="modalDetailShowType" :detailData="modalDetailShowData"></add-or-edit-modal>
  </a-modal>
</template>
<script>
import moment from "moment";
import {mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCheckBackListByCondition} from "A/wlw";
import deptselect from "@/mixins/deptselect";
import {getItemFromArrayByKey, getTimeRange} from "U/index";
import addOrEditModal from "V/supervise/improveOrder/addOrEditModal";
import {getImproveOrderListByCondition} from "A/patrol";
export default {
  mixins: [pagination,deptselect],
  props: {
    visible: {
      default: false
    },
    detailDatas: {
      default: null
    },
  },
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      modalVisible: false,
      modalShowType:'',
      modalDetailData:null,
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      userTypes:[],
      systemNames:[],
      queryParams:{
        checkername: '',
        checktime:'',
        userdepsid: '',
        starttime:'',
        endtime:'',
        eventtype:'',
        usertype:'',
        checktype:1,
        checkeraccount:'',
        improveordernum:'',
      },
      tableColumns: [
        {
          title: '查阅者姓名',
          dataIndex: 'checkername',
          key: 'checkername',
          ellipsis: true,
          width:80,
        },
        {
          title: '联系电话',
          dataIndex: 'checkerphone',
          key: 'checkerphone',
          ellipsis: true,
          width: 100
        },
        {
          title: '查阅时间',
          dataIndex: 'checktime',
          key: 'checktime',
          ellipsis: true,
          scopedSlots: { customRender: 'checktime' },
          width: 180
        },
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
          width: 200
        },
        {
          title: '品质改进单号',
          dataIndex: 'eventnum',
          key: 'eventnum',
          ellipsis: true,
          width: 100
        },
        {
          title: '发单时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          ellipsis: true,
          scopedSlots: { customRender: 'eventstarttime' },
          width: 180
        },
        {
          title: '角色',
          dataIndex: 'usertype',
          key: 'usertype',
          ellipsis: true,
          scopedSlots: { customRender: 'usertype' },
          width: 100
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      tableData: [],
      tableLoading: false,
      modalDetailVisible: false,
      modalDetailShowType:'',
      modalDetailShowData:null,
      isclickrow:false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return '品质改进单查阅记录'
    },
    modalTitle() {
      return '品质改进单查阅记录'
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.queryParams.starttime=this.detailDatas.starttime
        this.queryParams.endtime=this.detailDatas.endtime
        this.getTableData(true);
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    this.initDeptOptionsAll()
  },
  methods: {
    getTableData(firstPage){
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      this.queryParams.checkeraccount=this.detailDatas.useraccount
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getCheckBackListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
    },
    resetDatas() {
      this.$refs.queryForm.resetFields();
    },
    getImproveOrderByCondition(improveorderid){
      let params = {
        improveorderid:improveorderid,
      };
      getImproveOrderListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.modalDetailShowData = res.item[0];
          this.modalDetailShowData.improveorderid=res.item[0].improveorderid
          this.modalDetailShowData.orderstime=this.modalDetailShowData.orderstime?moment(this.modalDetailShowData.orderstime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
          this.modalDetailShowData.analysistime=this.modalDetailShowData.analysistime?moment(this.modalDetailShowData.analysistime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
          this.modalDetailShowData.improvetime=this.modalDetailShowData.improvetime?moment(this.modalDetailShowData.improvetime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
          this.modalDetailShowData.scheduledtime=this.modalDetailShowData.scheduledtime?moment(this.modalDetailShowData.scheduledtime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
          this.modalDetailShowData.verificationtime=this.modalDetailShowData.verificationtime?moment(this.modalDetailShowData.verificationtime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
          this.modalDetailVisible = true;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            if(!this.isclickrow) {
              this.modalDetailShowType = "detail";
              this.getImproveOrderByCondition(record.eventrecordid)
            }
          },
        },
      }
    },
    showModal(type, value) {
      this.modalDetailShowType = type;
      this.getImproveOrderByCondition(value)
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let eventrecordid = arr[1];
      if(type == 'delete') {
      }else{
        this.showModal(type, eventrecordid)
      }
    },
  }
}
</script>